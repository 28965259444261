//@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;700&family=Work+Sans:ital,wght@0,500;0,600;0,700;1,500;1,600;1,700&display=swap');

html{
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: $very-light-grey;

  color: $dark;
  // font-size: 20px;

}

.zz0 {
  z-index: 0;

}

.zz1 {
  z-index: 1;

}

.zz2 {
  z-index: 2;

}

.zz-1 {
  z-index: -1;

}

.my-background {
  position: absolute;
  width: 100%;
  z-index: -1;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

}

a {
  color: $primary;
  text-decoration: none;
  font-weight: 700;

  &:hover:not(.wp-block-button__link) {
    text-decoration: underline;
  }
}

p:not(:last-child) {
  color: $dark;
  font-weight: 500;
  margin-bottom: 25px;
}

h1,
.h1 {

  line-height: 44px;
  font-weight: 300;
  color: $dark;
  margin-bottom: 44px;
  text-transform: uppercase;
  word-wrap: break-word;

}

h2,
.h2 {
  line-height: 30px;
  font-weight: 700;
  color: $primary;
  margin-bottom: 44px;
}

h3,
.h3 {
  line-height: 25px;
  font-weight: 600;
  color: $dark;
  margin-bottom: 44px;
}

h4,
.h4 {
  line-height: 28px;
  font-weight: 500;
  color: $dark;
  margin-bottom: 44px;
}



.container-small {
//  max-width: 540px;
  margin: 0 calc(50% - 50vw);
  padding-top: 80px;
  padding-bottom: 80px;
  padding-inline: 44px;
}

.wp-block-button__link,
.wp-block-button__link a,
.wp-block-buttons .wp-block-button .wp-block-button__link
 {
 // background: #B6ED00 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
  text-align: center;
  font-family: $secondary-font;
  font-size: 22px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0px;
  color: #171717;
  opacity: 1;
  padding: calc(.35em + 2px) calc(0.5em + 2px);
}

.wpcf7 input[type=submit]{
  background: #B6ED00 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
  text-align: center;
  font-family: $secondary-font;
  font-size: 22px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0px;
  color: #171717;
  opacity: 1;
  padding: calc(.35em + 2px) calc(0.5em + 2px);
  width: min-content ;
  margin:0 auto;
  right: -35px;
	position: relative;
}

.wpcf7 input[type=submit],
.wp-block-button__link {
  transition: .5s;

  &:hover {
    transform: scale(1.1)
  }
}

.wpcf7-response-output{
  color:white;
}

.has-gray-dark-background-color {

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  li,
  p {

    color: white;
  }

  a:not(.wp-block-button__link) {
    color: $yellow;
  }
}
.has-green-background-color {
color:white;

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
li,
p {
  color:white;
}
a:not(.wp-block-button__link) {
  color: $yellow;
}
.cta-ancora {
  font-size: 60px;


  a {
    color: $teal ;

    &:hover {
      color: $yellow;
    }
  }
}
}


.breadcrumb{
  border-top:1px solid $gray-800;
  min-height:44px;
  font-size: 20px;
  line-height: 44px;
}

G

#separatore-sintomi .wp-block-separator{
  opacity:1;
  border-width: 3px;
  -webkit-border-radius: 100px;
-moz-border-radius: 100px;
border-radius: 100px;

}


/*** ! SINTOMI ****/
.carosello-sintomi {
  position: relative;
 height: 530px;


}

.swiper {

  height: 100%;
}

.swiper-slide {
  border-radius: 10px;
  overflow: hidden;

  &:hover {
    img {
      transform: scale(1.2);
    }

    .link-sintomo {
      .ico-vai-sintomo {
        color: #B6ED00 !important;
      }
    }
  }
}

.img-sintomo {
  display: block;
  width: 100%;
  //min-height: 400px;
  //margin-bottom: 44px;
  aspect-ratio: 1.1;
  overflow: hidden;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  img {
    object-fit: cover;
    transition: 1s;

  }
}


.service-intro {
  padding: 0 44px;

  .curva-sintomo {
    border: 30px solid white;
    opacity: 0.15;
    z-index: -2;
    width: 200px;
    height: 200px;
    position: absolute;
    bottom: -30px;
    right: -30px;
    -webkit-border-top-left-radius: 100px;
    -moz-border-radius-topleft: 100px;
    border-top-left-radius: 100px;
  }



  .link-sintomo {
    text-decoration: none;
    &:hover{
      text-decoration: none;
    }

    .ico-vai-sintomo {
      position: absolute;
      right: 44px;
      bottom: 34px;
      display: inline-block;
      margin-left: 22px;
      font-size: 30px;
      transition: 0.8s;
      color: white;
    }
  }

  .hr-sintomo {
    background-color: #B6ED00;
    height: 5px;
    opacity: 1;
    margin: 0;
    border:none;
    //  margin-bottom: 44px;
  }
  .post-sintomo {
 
    text-align: right;
    color:white;
  
  
  
  }

}

.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -60px !important;
}

.swiper-pagination-bullet-active {
  background-color: $primary !important;
}

.swiper-button-next {
  &.next-sintomo {
    color: $primary !important;
   // right: calc((100vw - 1320px)/5);
    //right: var(--swiper-navigation-sides-offset,5%) !important;
  }
}

.swiper-button-prev {
  &.prev-sintomo {
    color: $primary !important;
   // left: calc((100vw - 1320px)/5);
  }
}

.swiper-button-disabled {
  color: #d5d5d5 !important;
}





/*RECENSIONI */
.gspb_slider-id-gsbp-02bb68f .swiper-button-next,
.gspb_slider-id-gsbp-02bb68f .swiper-button-prev{
display: none;
 &.swiper-button-disabled {
  background-color: #d5d5d5;
  color: white !important;
  opacity: 1;
  }
}



.gspb_slider-id-gsbp-02bb68f {
  padding-inline: 0px;
}

.slider-content-zone {
    .wp-block-quote {
    padding: 44px;
    background-color: $light-grey;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    font-size: 20px;

    &::before {
      content: '"';
      font-size: 133px;
      line-height: 70px;
      font-family: 'Montagu Slab', serif;
    }

    .blockquote-footer::before,
    .wp-block-quote cite::before {
      content: "";
    }
  }

}



/****** ! PAGINE ********/
.sfondo-head {
  position: absolute;
top: 10%;
left:2%;
}

.cta-ancora {
  font-size: 60px;


  a {
    color: $teal ;

    &:hover {
      color: $primary;
    }
  }
}

.fascia-grigia-head {
  -webkit-border-bottom-left-radius: 120px;
  -moz-border-radius-bottomleft: 120px;
  border-bottom-left-radius: 120px;
}

.img-testata {
  -webkit-border-bottom-left-radius: 120px;
  -moz-border-radius-bottomleft: 120px;
  border-bottom-left-radius: 120px;
  overflow: hidden;
  height: 90%;
  position: relative;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

/*** ! elenco puntato **/
ul {
  margin-bottom: 44px;
  padding-inline-start: 20px;

  li {
    font-weight: 600;
    line-height: 38px;

    &::marker {
      color: $primary;
      font-size: 28px;
      bottom: 0;
    }
  }
}

Ul:last-child{
  margin-bottom: 0;
}


/*** ! scheda patologia* */



.scheda-patologia {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

 
  .pato-sx {
    img {
      margin-bottom: 44px;
      width: 75px;
    }
  }

  .fasce-eta-blocco {
    margin-bottom: 45px;

    p {
      display: inline-block;
      margin-bottom: 15px;
    }

    a {

      .fascia-eta {

        color: $gray-800;
        text-decoration: none;

        background-color: white;
        padding-inline: 10px;
        display: inline-block;
        height: 70px;
        min-width: 70px;
        font-size: 18px;
        font-weight: 500;
        -webkit-border-radius: 500px;
        -moz-border-radius: 500px;
        border-radius: 500px;
        margin-bottom: 0;
        text-align: center;
        vertical-align: middle;
        margin-left: 15px;
        margin-bottom:15px;

        &:hover {
          background-color: $teal;
          color: white;
          text-decoration: none;
        }

        &:active {
          background-color: $primary;
        }

        span {
          position: relative;
          top: 28%;
        }

      }


    }
  }

  .box-tratt {
    -webkit-border-top-left-radius: 40px;
    -moz-border-radius-topleft: 40px;
    border-top-left-radius: 40px;

    h4 {
      text-transform: uppercase;
    }
    ul{
      padding-inline-start:0px;
    }
    .list-group-item {
      padding-inline: 0px;
      padding-block: 0px;

      &:not(:last-of-type) {
        border-bottom: 1px solid white;
      }

      a {
        color: white;
        font-weight: 500;
        width: 100%;
        display: block;

        &:hover {
          text-decoration: none;
        }
      }

      &:hover {
        background-color: $teal;
        text-decoration: none;
      }
    }
  }

}

/***** singolo sintomo ***/
.single-sintomi{
  .img-testata {
    -webkit-border-bottom-left-radius:0px;
  -moz-border-radius-bottomleft: 0px;
  border-bottom-left-radius: 0px;
  -webkit-border-bottom-right-radius:120px;
  -moz-border-radius-bottomright: 120px;
  border-bottom-right-radius: 120px;
  overflow: hidden;
  height: 90%;
  position: relative;

  }
  .sfondo-head{
    
   top:20%;
    bottom:0px;
    left:70%;
    img{
      width: 100%;
    }

  }
}







/* ! singola patologia  **/

.single-patologie {
  .fasce-eta-blocco {
    margin-bottom: 0px;
  }

  .fascia-grigia-head {
    margin-left: -25px;
  }

  .text-end {
    margin-right: -10px;
  }

  .ico-testata {
    width: 30px;
    display: inline-block;
  }

  .voce-pato {
    display: inline;
    font-weight: 700;
  }

  .primariga {
    vertical-align: middle;
    margin-bottom: 44px;
  }

/* ! scheda sintomo no carousel  ***/

  .scheda-sintomo{
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;

    &:hover{
      img{
        transform: scale(1.2);
      }
      .link-sintomo{
        text-decoration: none;
        .ico-vai-sintomo{
          color: #B6ED00;
        }
      }
    }
  }
  .img-sintomo {
    display: block;
    width: 100%;
    min-height:auto;
    margin-bottom: 25px;
    overflow: hidden;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  
    img {
      object-fit: cover;
      transition: 1s;
  
    }
   
  }
  
  .service-intro {
    padding: 0 25px;
    position: relative;
    overflow: hidden;

      h3{
      font-size:25px;
      line-height: 30px;
      margin-block-end: 25px;
    }
      .curva-sintomo {
      border: 30px solid white;
      opacity: 0.15;
      z-index: 0;
      width: 200px;
      height: 200px;
      position: absolute;
      bottom: -69px;
      right: -76px;
      -webkit-border-top-left-radius: 100px; 
      -moz-border-radius-topleft: 100px;
      border-top-left-radius: 100px;
    } 
  
    .link-sintomo {
      text-decoration: none;
  
      .ico-vai-sintomo {
        position: absolute;
        right: 0;
        bottom: 15px;
        display: inline-block;
        margin-left: 22px;
        font-size: 30px;
        transition: 0.8s;
        color: white;
      }
    }
  
    .hr-sintomo {
      background-color: #B6ED00;
      height: 3px;
      opacity: 1;
      margin: 0;
      //  margin-bottom: 44px;
    }
  
    .post-sintomo {
      padding: 25px 44px 25px 0;
      text-align: right;
      position: relative;
      color: white;
  
    }
  
  }
} /* fine .single-patologia */

.hr-pato {
  background-color: $teal;
  height: 5px;
  opacity: 1;
  margin-bottom: 80px;
}

.spostalo {
  -webkit-border-top-left-radius: 200px;
  -moz-border-radius-topleft: 200px;
  border-top-left-radius: 200px;
  margin-left: 25px;
}

/****ARCHIVIO TRATTAMENTI ****/
.tax-fasce-eta,
.page-template-archivepatopage,
.page-template-archivetrattpage{
  .sfondo-head{
    top:10%;
     img{
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    position: absolute;
    opacity: 0.05;
    }
    width: 100%;
  }
}




/*** ! scheda  trattamenti ****/

.schede-trattamenti{
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  img{
    width:85px;
    margin-bottom:44px;
  }
}
/*** ! singola pagina  trattamento ****/

.single-trattamenti{
  .ico-testata {
    width: 30px;
    display: inline-block;
  }
  .voce-tratt {
    display: inline;
    font-weight: 700;
  }
  .primariga {
    vertical-align: middle;
    margin-bottom: 44px;
  }
  .scheda-trattamento{
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;

    
  }
  .fascia-grigia-head {
    margin-left: -25px 
  }
  .head-dx{
    margin-right: -10px
  }
}


/******** Tabella contatti  ***********/


.frasetta{
  
    color:$teal;
    font-size: 20px;
    margin-bottom: 5px;
	font-weight: bold;
  

}
.etichetta-sedi{
  font-size: 45px;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0;
}
/*
.pre-blocco-sede{ 

  &:not( :last-child){
    .bordo{
   border-bottom: 2px solid $gray-800;
   padding-bottom: 80px;
   padding-right: 80px;
  //  margin-right: -80px;
    }
  }
 }
*/


.blocco-sede{
 // background-color: red;
 i.fa-phone {
  display: inline-block;
  border-radius: 60px;
  //box-shadow: 0 0 2px #888;
  padding:  0.6em;
  background-color: $teal;
  color:white;

}

i.fa-location-pin {
  display: inline-block;
  border-radius: 60px;
  //box-shadow: 0 0 2px #888;
  padding:   0.6em 0.7em;
  background-color: $teal;
  color:white;

}

}

.wpcf7{
  max-width: 100% !important;
}
/*
.wpcf7 input[type=submit]
{
  background: #B6ED00 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
  text-align: center;
  font-family: $secondary-font;
  font-size: 22px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0px;
  color: #171717;
  opacity: 1;
  padding: calc(.35em + 2px) calc(0.5em + 2px);
  width: 30%;

}
*/
.tondo-dx{
  margin-bottom: 25px;
}


/**** ! Footer ***/

.footer-alto {
 // padding: 136px 0;
}

.copy{
  font-size: 16px;
 margin-bottom: 0;
 
}
.footer-sotto{
  figure{
    margin-bottom:44px !important;
  }
}

.login-foot{
text-align: right;
}

.nav-foot{
  font-size: 16px;
  margin-top: -8px;
 
  .wp-block-navigation-item{
    text-transform: uppercase;
    margin-inline: 15px;
    a{
      font-weight: regular;
   
      &:hover{
        font-weight: bold;
        text-decoration: none;
      }
    }
  }
}

.ico-login{
color:$teal !important;
font-size: 25px;
&:hover{
  color:$primary !important;
}

}
.prenota-cart {
  -webkit-border-top-left-radius: 80px;
  -moz-border-radius-topleft: 80px;
  border-top-left-radius: 80px;
  margin-block: auto;
      .wp-block-button__link,
    .wp-block-button__link a,
    .wp-block-buttons .wp-block-button .wp-block-button__link{
      background: #B6ED00 0% 0% no-repeat padding-box;
    }
}
.spalla-footer{
  bottom: -22px;
	z-index: 0;
}

/*** ! breakpoint sotto SM !SM 576 **/
@include media-breakpoint-down(sm) {
.container{
 // padding-inline: 2%;
}


.arco-verde {
  //background-color: #2f837b;
  width: 300px;
  height: 300px;
  position: absolute;
  top: 10%;
  left: 0 ;
  transform: translateX(calc(50vw - 144px));
  z-index: -1;
  -webkit-border-top-left-radius: 220px;
  -moz-border-radius-topleft: 220px;
  border-top-left-radius: 220px;
  border-top:70px solid $teal;
  border-left:70px solid $teal;
}

.arcobasso{
  width: 300px;
  height: 300px;
  bottom:0;
  right: 0;
  position: absolute;
  z-index: -1;
  -webkit-border-top-left-radius: 220px;
  -moz-border-radius-topleft: 220px;
  border-top-left-radius: 220px;
  border-top:70px solid $light-grey;
  border-left:70px solid $light-grey;
}

/**** FRECCE DEL CAROSELLO ***/
.swiper-button-next {
  &.next-sintomo {
   display:none;
    right: calc((100vw - 320px)/5);
    //right: var(--swiper-navigation-sides-offset,5%) !important;
  }
}

.swiper-button-prev {
  &.prev-sintomo {
    display:none;
    left: calc((100vw - 320px)/5);
  }
}
}

/*** ! breakpoint sopra SM !SM 576 **/
@include media-breakpoint-up(sm) {

.arco-verde {
  //background-color: #2f837b;
  width: 400px;
  height: 400px;
  position: absolute;
  top: 13%;
  left: 0 ;
  transform: translateX(calc(50vw - 230px));
  z-index: -1;
  -webkit-border-top-left-radius: 300px;
  -moz-border-radius-topleft: 300px;
  border-top-left-radius: 300px;
  border-top:80px solid $teal;
  border-left:80px solid $teal;
}
.carosello-sintomi {

	height:700px;
}

.arcobasso{
  width: 400px;
  height: 400px;
  bottom:0;
  right: 0;
  position: absolute;
  z-index: -1;
  -webkit-border-top-left-radius: 220px;
  -moz-border-radius-topleft: 220px;
  border-top-left-radius: 220px;
  border-top:80px solid $light-grey;
  border-left:80px solid $light-grey;
}

/**** FRECCE DEL CAROSELLO ***/
.swiper-button-next {
  &.next-sintomo {
  
    right: calc((100vw - 442px)/5);
    //right: var(--swiper-navigation-sides-offset,5%) !important;
  }
}

.swiper-button-prev {
  &.prev-sintomo {

    left: calc((100vw - 442px)/5);
  }
}

}

/*** ! breakpoint sopra MD !MD 782 **/
@include media-breakpoint-up(md) {

  .container-small {
    max-width: 720px;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-inline: 3%;


  }

  .arco-verde {
    //background-color: #2f837b;
    width: 400px;
    height: 400px;
    position: absolute;
    top: 20%;
    left: 0 ;
    transform: translateX(calc(100vw / 4 - 210px));
    z-index: -1;
    -webkit-border-top-left-radius: 290px;
    -moz-border-radius-topleft: 290px;
    border-top-left-radius: 290px;
    border-top:90px solid $teal;
    border-left:90px solid $teal;
  }

  .arcobasso{
    width: 400px;
    height: 400px;
    bottom:0;
  right: 0;
    position: absolute;
    z-index: -1;
    -webkit-border-top-left-radius: 290px;
    -moz-border-radius-topleft: 290px;
    border-top-left-radius: 290px;
    border-top:90px solid $light-grey;
    border-left:90px solid $light-grey;
  }
  
  /**** FRECCE DEL CAROSELLO ***/
.swiper-button-next {
  &.next-sintomo {
  
    right: calc((100vw - 678px)/5);
    //right: var(--swiper-navigation-sides-offset,5%) !important;
  }
}

.swiper-button-prev {
  &.prev-sintomo {

    left: calc((100vw - 678px)/5);
  }
}

.carosello-sintomi {

	height: 590px;
}
.tondo-sx{
  -webkit-border-top-left-radius: 10px;
-webkit-border-bottom-left-radius: 10px;
-moz-border-radius-topleft: 10px;
-moz-border-radius-bottomleft: 10px;
border-top-left-radius: 10px;
border-bottom-left-radius: 10px;


}

.tondo-dx{
  -webkit-border-top-right-radius: 10px;
-webkit-border-bottom-right-radius: 10px;
-moz-border-radius-topright: 10px;
-moz-border-radius-bottomright: 10px;
border-top-right-radius: 10px;
border-bottom-right-radius: 10px;
margin-bottom: 0;
}

.pre-blocco-sede{
 position:relative;
}



.scheda-patologia{
  .box-tratt{
    ul{
      padding-inline-start:20px;
    }
    .list-group-item {
      padding-inline: 25px;
      padding-block: 10px;
  }
}
}

/*slider recensioni da MD in su */
.gspb_slider-id-gsbp-02bb68f .swiper-button-next,
.gspb_slider-id-gsbp-02bb68f .swiper-button-prev{
display: flex;
}
.gspb_slider-id-gsbp-02bb68f {
  padding-inline: 44px;
  .swiper-pagination-bullets.swiper-pagination-horizontal{
    display: none;
  }
}

 
/*
 .border-between > [class*='col-']:before {
  background: red;
  bottom: 0;
  content: " ";
  left: 0;
  position: absolute;
  width: 3px;
  height: 80%;
  top: 10%;
}

.border-between > [class*='col-']:last-child:before {
  display: none;
}

.border-between > [class*='col-']:nth-child(2n+1)::before {
  display: none;
}*/

}
/***** FUNE UP MD vero ****/






/*** ! breakpoint sotto !LG 991 **/

@include media-breakpoint-down(lg){

  .brbr{
    border-end-end-radius: 200px 200px;
  }

  .copy{
    text-align: center;
    padding-bottom: 44px;
    border-bottom: 2px solid $primary;
  }

  .nav-foot{
    text-align: center;
    padding-block: 44px;
    border-bottom: 2px solid $primary;
  }
  .login-foot{
    text-align: center;
    padding-top: 44px;
  }

/***** ! menu mobile  ****/

.navbar-toggler{
  border:none;
  &:focus{
       border:none;   
       box-shadow: none;
  }
}

.navbar-toggler[aria-expanded="true"] span:nth-child(1) {
transform: translateY(5px) rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] span:nth-child(2) {
transform: translateY(-5px) rotate(-45deg);
}

.navbar-toggler[aria-expanded="true"] span:nth-child(3) {
display: none;
}
.lgrigia{
  width: 37px;
height: 4px;
background: $gray-800;
margin-top: 8px;
display: block;
border-radius: 550px;
transition: all 0.5s cubic-bezier(.215, .61, .355, 1);
}

.lverde{
  width: 37px;
height: 4px;
background: $teal;
margin-top: 8px;
display: block;
border-radius: 550px;
transition: all 0.5s cubic-bezier(.215, .61, .355, 1);
}
.lgrigia2{
  width: 37px;
height: 4px;
background: $gray-800;
margin-top: 8px;
border-radius: 550px;
display: block;
transition: all 0.5s cubic-bezier(.215, .61, .355, 1);
}

.lbianca{
  width: 37px;
  height: 4px;
  background: white;
  margin-top: 8px;
  border-radius: 550px;
  display: block;
 // transition: all 0.5s cubic-bezier(.215, .61, .355, 1);
  transform: translateY(-5px) rotate(-45deg);
}

.btn-close{
  background: none;
  opacity: 1;
}

.lbianca1{
  width: 37px;
  height: 4px;
  background: white;
  margin-top: 8px;
  border-radius: 550px;
  display: block;
//  transition: all 0.5s cubic-bezier(.215, .61, .355, 1);
  transform: translateY(-16px) rotate(45deg);
}
.offcanvas-backdrop{
  opacity: 1;
  background-color: rgba(15, 23, 42, 0.474);
  backdrop-filter: blur(10px);

  &.show{
opacity: 1;
  }
  &.fade{
    opacity: 1;

  }
}

.navbar-toggler{
  padding-right: 0;
}

.offcanvas{
  border: none;
  background-color:transparent;
  .nav-link{
   color:white;
    text-transform: uppercase;
  }
  .offcanvas-body{
    background-color: transparent;
    border:none;  
}
  &.show{
    border: none;
    
}
#main-menu{
  //  opacity: 1;

  color:white;

   li{
     
      ::before{
      content: "";
      //background-color: $teal;
      width: 33px;
      height: 36px;
      position: absolute;
      margin-top: -8px;
      margin-left: -24px;
      -webkit-border-top-left-radius: 25px;
      -moz-border-radius-topleft: 25px;
      border-top-left-radius: 25px;
      border-top:8px solid $teal;
      border-left:8px solid $teal;
      
     
    }
    .nav-link{
      padding-left: 25px;
      color:white;
      font-size: 23px;
      font-weight: bold !important;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
  }
  }
  
}

.post-sintomo {
  padding: 44px 44px 44px 0;

  font-size:18px;



}




} 
/***** fine down LG */


/*** ! breakpoint sopra LG  !LG 992**/
@include media-breakpoint-up(lg) {

  .container-small {
    max-width: 850px;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-inline: 2%;

  }

  .brbr{
    border-end-end-radius: 500px 500px;
  }

  /****** ! LG nav bar ****/
  .home {
    #main-nav.bg-white {

      background-color: transparent !important;
    }
  }

/* menu sopra LG*/
#main-menu {


  .nav-link {
    font-size: 23px;
    text-transform: uppercase;
    padding: 0 1.3rem;
    color:$gray-800;
    font-weight: 600;

    &:hover,
    &:focus {
      color: $teal;
      text-decoration: none;
    }
  }

  .active,
  .current_page_item {
    &::before {
      content: "";
      //background-color: $teal;
      width: 33px;
      height: 36px;
      position: absolute;
      margin-top: -10px;
      margin-left: -2px;
      -webkit-border-top-left-radius: 25px;
      -moz-border-radius-topleft: 25px;
      border-top-left-radius: 25px;
      border-top:8px solid $teal;
      border-left:8px solid $teal;
    }

    .nav-link {
      color: $teal;

    }
  }

}
/**** FRECCE DEL CAROSELLO ***/
.swiper-button-next {
  &.next-sintomo {
  
    right: calc((100vw - 880px)/5);
    //right: var(--swiper-navigation-sides-offset,5%) !important;
  }
}

.swiper-button-prev {
  &.prev-sintomo {

    left: calc((100vw - 880px)/5);
  }
}
.carosello-sintomi {

	height: 560px;
}

.post-sintomo {
  padding: 44px 44px 44px 0;
 
}




}

@include media-breakpoint-down(xl) {
  .post-sintomo {
   font-size:18px
   
  }


}

/*** ! !XL breakpoint sopra XL  1200**/
@include media-breakpoint-up(xl) {
  /***** ! container*********/

  .container-small {
    max-width: 952px;
    margin: 0 auto;
    padding-top: 135px;
    padding-bottom: 135px;
    padding-inline: 0;
  }


  h1,
  .h1 {
    font-size: 80px;
    line-height: 80px;
    font-weight: 300;
    color: $dark;
    text-transform: uppercase;
  }

  h2,
  .h2 {
    font-size: 45px;
    line-height: 47px;
    font-weight: 700;
    color: $primary;
  }

  h3,
  .h3 {
    font-size: 32px;
    line-height: 38px;
    font-weight: 600;
    color: $dark;
  }

  h4,
  .h4 {
    font-size: 26px;
    line-height: 29px;
    font-weight: 500;
    color: $dark;
  }

  .wp-block-buttons .wp-block-button .wp-block-button__link {

    text-align: center;
    font: normal normal medium 22px/25px Lexend;
    letter-spacing: 0px;
    color: #171717;
    opacity: 1;
  }


  
  .logo-dk {
    width: 290px;
  }





  /*** ! xl PAGINE  */
  .fascia-grigia-head {
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius-bottomleft: 0px;
    border-bottom-left-radius: 0px;
  }

  .img-testata {
    -webkit-border-bottom-left-radius: 200px;
    -moz-border-radius-bottomleft: 200px;
    border-bottom-left-radius: 200px;


  }
  .arco-verde {
    //background-color: #2f837b;
    width: 500px;
    height: 500px;
    position: absolute;
    top: 20%;
    left: calc((100vw /4) - 310px);
    //transform: translateX(calc(100vw / 4 - 50px));
    z-index: -1;
    -webkit-border-top-left-radius: 390px;
    -moz-border-radius-topleft: 390px;
    border-top-left-radius: 390px;
    border-top:100px solid $teal;
    border-left:100px solid $teal;
  }


.arcobasso{
  width: 500px;
  height: 500px;
  position: absolute;
  bottom:0;
  right: 0;
  z-index: -1;
  -webkit-border-top-left-radius: 390px;
  -moz-border-radius-topleft: 390px;
  border-top-left-radius: 390px;
  border-top:100px solid $light-grey;
  border-left:100px solid $light-grey;
}
  
/**** FRECCE DEL CAROSELLO ***/
.swiper-button-next {
  &.next-sintomo {
  
    right: calc((100vw - 1040px)/5);
    //right: var(--swiper-navigation-sides-offset,5%) !important;
  }
}

.swiper-button-prev {
  &.prev-sintomo {

    left: calc((100vw - 1040px)/5);
  }
}
.carosello-sintomi {

	height: 700px;
}


/*****SINGOLA PATOLOGIA  XL in su***/
.spostalo {
  -webkit-border-top-left-radius: 500px;
  -moz-border-radius-topleft: 500px;
  border-top-left-radius: 500px;
  margin-left: 200px;
}
.single-patologie {
  .fasce-eta-blocco {
    margin-bottom: 0px;
  }

  .fascia-grigia-head {
    margin-left: -200px;
  }

  .text-end {
    margin-right: -200px;
  }
  .scheda-patologia{
    h1{
      font-size: 44px;
      line-height: 44px;
    }
  }
}



.single-trattamenti{
  .fascia-grigia-head {
    margin-left: -200px;
  }
  .head-dx {
    margin-right: -200px;
  }
 .scheda-trattamento{
  h1{
    font-size: 44px;
    line-height: 44px;
  }
 }
}

}




/*** ! !XXL breakpoint sopra XXL  1400**/
@include media-breakpoint-up(xxl) {

/**** FRECCE DEL CAROSELLO ***/
.swiper-button-next {
  &.next-sintomo {
  
    right: calc((100vw - 1328px)/5);
    //right: var(--swiper-navigation-sides-offset,5%) !important;
  }
}

.swiper-button-prev {
  &.prev-sintomo {

    left: calc((100vw - 1328px)/5);
  }
}
.carosello-sintomi {

	height: 725px;
}

.single-trattamento{
  .scheda-trattamento{
    h1{
      font-size: 55px;
      line-height: 55px;
    }
}
}
.single-patologie{
  .scheda-patologia{
    h1{
      font-size: 55px;
      line-height: 55px;
    }
  }
}

}



/************ ANIMAZIONI   *******/

.shake-vertical {
  -webkit-animation: shake-vertical 7s ease-in-out infinite both;
  animation: shake-vertical 7s ease-in-out infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-1-30 14:22:47
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-vertical
 * ----------------------------------------
 */
@-webkit-keyframes shake-vertical {

  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }

  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }

  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}

@keyframes shake-vertical {

  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }

  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }

  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}

.shake-horizontal {
	-webkit-animation: shake-horizontal 15s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
	        animation: shake-horizontal 15s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}

.shake-horizontal2 {
	-webkit-animation: shake-horizontal 30s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
	        animation: shake-horizontal 30s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}



/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
 @-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}


/*animazione*/
/***** !!! animation*****/
.animate{
  opacity:0;
}

.in-page{
  &.slide-in-left {
       -webkit-animation: slide-in-left  0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s both;
               animation: slide-in-left 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s both;
  }
  &.slide-in-leftd {
    -webkit-animation: slide-in-left  0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.6s both;
            animation: slide-in-left 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.6s both;
}
  &.slide-in-right {
       -webkit-animation: slide-in-right 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s both;
               animation: slide-in-right 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s both;
  }
  &.scale-in-center {
       -webkit-animation: scale-in-center 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
               animation: scale-in-center 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  &.flip-in-ver-right {
    -webkit-animation: flip-in-ver-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both;
            animation: flip-in-ver-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both;
  }
  &.flip-in-hor-bottom {
    -webkit-animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  &.flip-in-diag-2-tl {
    -webkit-animation: flip-in-diag-2-tl 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: flip-in-diag-2-tl 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  &.swing-in-right-fwd {
    -webkit-animation: swing-in-right-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
            animation: swing-in-right-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
  }
  &.slide-in-blurred-left {
    -webkit-animation: slide-in-blurred-left 0.9s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
            animation: slide-in-blurred-left 0.9s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
          
  }
  &.slide-in-blurred-right {
    -webkit-animation: slide-in-blurred-right 0.9s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
            animation: slide-in-blurred-right 0.9s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
  }
  &.heartbeat {
    -webkit-animation: heartbeat 3s ease-in-out 1s infinite both;
            animation: heartbeat 3s ease-in-out 1s infinite both;
            opacity: 1;
  }
  &.scale-in-center {
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
}

.slide-in-blurred-left {
  -webkit-animation: slide-in-blurred-left 0.9s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
          animation: slide-in-blurred-left 0.9s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
        
}
.slide-in-blurred-right {
	-webkit-animation: slide-in-blurred-right 0.9s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	        animation: slide-in-blurred-right 0.9s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

/**
* ----------------------------------------
* animation slide-in-left
* ----------------------------------------
*/
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-leftd {
  0% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-leftd {
  0% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}


/**
* ----------------------------------------
* animation slide-in-right
* ----------------------------------------
*/
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

/**
* ----------------------------------------
* animation scale-in-center
* ----------------------------------------
*/
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}


.vaidestra{
  /* Copy code to element you want to animate */
animation: vaidestra 45s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s infinite normal none;
}

@keyframes vaidestra {
  0% {
	//transform: translateX(0);
  }

  100% {
	transform: translateX(25vw);
  }
}
/**
 * ----------------------------------------
 * animation flip-in-ver-right
 * ----------------------------------------
 */
 @-webkit-keyframes flip-in-ver-right {
  0% {
    -webkit-transform: rotateY(-80deg);
            transform: rotateY(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    opacity: 1;
  }
}
@keyframes flip-in-ver-right {
  0% {
    -webkit-transform: rotateY(-80deg);
            transform: rotateY(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation flip-in-hor-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation flip-in-diag-2-tl
 * ----------------------------------------
 */
 @-webkit-keyframes flip-in-diag-2-tl {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 80deg);
            transform: rotate3d(-1, 1, 0, 80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
}
@keyframes flip-in-diag-2-tl {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 80deg);
            transform: rotate3d(-1, 1, 0, 80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
  
}
/**
 * ----------------------------------------
 * animation swing-in-right-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes swing-in-right-fwd {
  0% {
    -webkit-transform: rotateY(-100deg);
            transform: rotateY(-100deg);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 1;
  }
}
@keyframes swing-in-right-fwd {
  0% {
    -webkit-transform: rotateY(-100deg);
            transform: rotateY(-100deg);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 1;
  }
}


/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
 @-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

.heartbeat {
	-webkit-animation: heartbeat 1.5s ease-in-out both;
	        animation: heartbeat 1.5s ease-in-out both;
}


/**
 * ----------------------------------------
 * animation slide-in-blurred-left
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
